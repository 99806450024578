@import "./style/mixins";
@import "./style/fonts.scss";

html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  scroll-behavior: smooth;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  margin: 0;
  font-family: "HyundaiRegular", "NotoSansArabicRegular", "sans-serif";
  font-size: 16px;

  &.fixed {
    overflow: hidden;
    height: 100%;
  }
}

*::-webkit-scrollbar {
  width: 3px;
  height: 0;
}

*::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #002C5E;
}

*::-webkit-scrollbar-track {
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    transparent 33%,
    #DEE1EA 33%,
    #DEE1EA 66%,
    transparent 66%,
    transparent 100%
  );
}

.container {
  width: 100%;
  max-width: 1260px;
  padding: 0 30px;
  margin: 0 auto;

  &.no-padding {
    padding: 0;
  }
}

.preload-logo {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 280px;
    height: auto;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  font-family: "HyundaiRegular", "NotoSansArabicRegular", "sans-serif";


  img {
    width: 100%;
    height: auto;
  }

  img, svg {
    display: block;
  }

  textarea {
    resize: none;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;

    img {
      border: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }
}

.pp-section {
  padding: 96px 0;
  overflow: hidden;

  &.no-padding {
    padding: 0;
  }

  &.no-top {
    padding-top: 0;
  }

  &.pp-section-her-drive {
    background-color: #f6f3f2;
  }

  @include break-point(mobile) {
    padding: 64px 0;
  }

  @include break-point(s_mobile) {
    padding: 36px 0;
  }
}

.counter {
  background-color: $primary-color;
  padding: 3px 12px;
  border-radius: 14px;
  @include text-small;
  color: #ffffff;
  display: inline-block;
}

main {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .image.top-button {


    &.mobile {
      display: none;
    }


  }

  .pp-section-learn-more {
    background-color: $light-brown;
    .btn {
      cursor: pointer;
      font-size: 16px;
    }

    @media (max-width: 660px) {
      .btn:first-child {
        margin-bottom: 32px;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
}

.pp-page-elantra.rtl * {
  //font-family: "HyundaiMedium", "NotoSansArabicMedium" !important;
}

@include break-point(mobile) {

  main {
    &[dir="rtl"] {
      .title-block {

        h1,
        h2 {
          // line-height: 1.8;
        }
      }
    }

    .pp-section-learn-more {
      .subtitle {
        max-width: 504px;
        text-align: center;
        margin: 0 auto;
        margin-top: 24px;
      }
    }

    .image.top-button.mobile {
      position: fixed;
      bottom: 20px;
      right: 16px;
      z-index: 9;
      width: 60px;
      height: 60px;
      padding: 10px;

      &:after {
        display: inline-block;
        content: "";
       @include positionCC;
        z-index: 10;
        width: 39px;
        height: 39px;

        background: #fff;
        border-radius: 50%;
      }

      img {
        z-index: 15;
        width: 40px;
        height: 40px;
      }

      display: block;
    }
  }

  .no-scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .container {
    padding: 0 36px;

    &.slider-container {
      padding: 0;
    }
  }
}

@include break-point(sm_mobile) {
    .container {
        padding: 0 16px;
    }
}

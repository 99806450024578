@import "./media.scss";

$primary-color: #00AAD2;
$gray-color: #EBEDF3;
$dark-color: #002C5E;
$sand: #d4c8bb;
$light-sand: #f6f3f2;
$light-brown: #F6F3F2;
$gray: #666666;
$grey: #817C77;
$light-gray: #DEE1EA;
$light-blue: #00AAD2;
$light-blue-opacity3: rgba(0, 170, 210, 0.3);
$light-blue-opacity0: rgba(0, 170, 210, 0);
$white: #fff;
$black: #000;
$gray-1: #9E9E9E;
$gray-2: #ecedf3;
$red: #EB3D3A;
$dark-blue: #103A71;
$rose-color: #FAF8F9;
$green: #1FD631;

$ff_medium: "HyundaiMedium", "NotoSansArabicMedium", "Arial", "sans-serif";

$icon-arrow: "data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15L8 8L1 0.999999' stroke='black'/%3E%3C/svg%3E%0A";

@mixin break-point($point) {
  @if $point == desktop-large {
    @media (min-width: 1920px) {
      @content;
    }
  }
  @else if $point == tablet {
    @media (max-width: 1023px) and (min-width: 768px){
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == m_mobile {
    @media (max-width: 767px) {
      @content
    }
  } @else if $point == sm_mobile {
    @media (max-width: 767px) {
      @content
    }
  } @else if $point == s_mobile {
    @media (max-width: 375px) {
      @content
    }
  }
}

@mixin HyundaiRegular($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  font-family: "HyundaiRegular","NotoSansArabicRegular", "Arial", "sans-serif";
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin HyundaiMedium($font-size, $line-height, $letter-spacing, $font-weight, $color) {
  font-family: "HyundaiMedium", "NotoSansArabicMedium", "Arial", "sans-serif";
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
}

@mixin positionCC {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin positionX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin positionY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin title-h1 {
  @include HyundaiMedium(70px, 100%, normal, 500, #000000);

  @include break-point(mobile) {
    //font-size: 9.3vw;
    font-size: 32px;
    line-height: 1.1;
  }

}
@mixin title-h2 {
  @include HyundaiMedium(58px, 64px, normal, 500, #000000);

  @include break-point(mobile) {
    font-size: 9.3vw;
    font-size: 40px;
    line-height: 1.1;
  }

  @include break-point(s_mobile) {
    font-size: 24px;
  }
}

@mixin title-h3 {
  @include HyundaiMedium(30px, 35px, normal, 500, #000000);

  @include break-point(mobile) {
    //font-size: 6.25vw;
    font-size: 20px;
    line-height: 1.25;
  }

}

@mixin text-subtitle {
  @include HyundaiRegular(20px, 30px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 5vw;
    font-size: 16px;
    line-height: 1.62;
  }
}

@mixin text-simple {
  @include HyundaiRegular(16px, 26px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 4.3vw;
    font-size: 16px;
    line-height: 30px;
  }
}

@mixin text-description {
  @include HyundaiRegular(16px, 26px, 0.02em, 400, $gray);
}

@mixin text-small {
  @include HyundaiRegular(14px, 18px, normal, 400, #000000);

  @include break-point(mobile) {
    //font-size: 3.75vw;
    font-size: 12px;
    line-height: 1.33;
  }
}

@mixin ItemsAdaptiveList($indent: 0px, $splitting: 3) {
  width: calc((100% / #{$splitting}) - #{$indent});

  @include break-point(m_mobile) {
    width: calc((100% / 2) - #{$indent});
  }

  @include break-point(s_mobile) {
    width: 100%;
  }
}

@mixin line-clamp(
  $font-size,
  $line-height,
  $lines-to-show
) {
  display: block;
  display: -webkit-box;
  font-size: rem($font-size);
  max-height: rem($line-height * $lines-to-show);
  line-height: rem($line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}
